var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-container"
  }, [_c('img', {
    staticClass: "background",
    attrs: {
      "src": "/login-background.svg",
      "alt": "brandwatch login background"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "d-flex flex-row justify-center align-center login-form-container"
  }, [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onClickLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-start"
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.title')) + " ")])]), _c('div', {
    staticClass: "d-flex justify-start"
  }, [_c('h4', {
    staticClass: "subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('login.subTitle')) + " ")])]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.loginError ? _c('v-alert', {
    attrs: {
      "dense": "",
      "dismissible": "",
      "tile": "",
      "text": "",
      "color": "grey darken-1",
      "close-label": "clear",
      "icon": "error",
      "type": "warning",
      "elevation": "0"
    }
  }, [_c('small', [_vm._v("Login failed - " + _vm._s(_vm.loginError))])]) : _vm._e()], 1), _c('r-text-field', {
    staticClass: "input-text",
    attrs: {
      "label": "",
      "placeholder": _vm.$t('login.fields.username.placeholder'),
      "rules": [_vm.rules.required, _vm.rules.email]
    },
    on: {
      "change": _vm.onUsernameInputChange
    }
  }), _c('r-text-field', {
    staticClass: "input-text",
    attrs: {
      "label": "",
      "type": "password",
      "placeholder": _vm.$t('login.fields.password.placeholder'),
      "rules": [_vm.rules.required, _vm.rules.min]
    },
    on: {
      "change": _vm.onPasswordInputChange
    }
  }), _c('r-button', {
    staticClass: "px-3 py-0",
    attrs: {
      "data-testid": "login-button",
      "elevation": "0",
      "type": "submit",
      "label": _vm.$t('login.actions.signIn'),
      "disabled": _vm.loginFetching,
      "loading": _vm.loginFetching
    },
    on: {
      "click": _vm.onClickLogin
    }
  })], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "logo-container"
  }, [_c('img', {
    attrs: {
      "src": "/logo-card.svg",
      "alt": "brandwatch login logo"
    }
  })]);
}]

export { render, staticRenderFns }